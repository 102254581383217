import { EditorReadyFn, GetAppManifest, ExportsFn, EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import * as componentsWrapper from './wrappers/components';
import * as pagesService from './services/pages';
import { hasSocialPages } from './services/pages';
import * as constants from './constants';
import * as applicationState from './applicationState';
import enforceSequentiality, { startSequentialPromises, stopSequentialPromises } from './enforceSequentiality';
import { BADGES_BM_URL, SITE_MEMBERS_BM_URL } from './constants/routes';
import {
  initializeMonitoring,
  interactionEnded,
  interactionFailed,
  interactionStarted,
  log,
  toMonitored,
} from '../utils/monitoring';
import { createAppManifest } from './manifest';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as appState from './services/applicationState';
import { createBIService } from '../utils/bi';
import * as membersIntegrationApi from './services/integration';
import { registerAlwaysAvailableApps } from './services/integration';
import { publishSettingsForMembersAreaApps } from './services/members-area';
import {
  maybeConductExperiments,
  shouldRevertOnInstallationErrors,
  shouldListenToComponentAddedToStageEvent,
  shouldOpenConfirmationPanelOnLoginBarRemove,
} from '../utils/experiments';
import { runAndWaitForApproval } from './wrappers/transactions';
import {
  openRemoveLoginBarConfirmationPanel,
  openRemovePagePanel,
  openUninstallPanel,
} from './wrappers/platformPanels';
import { getTranslationFunction } from '../i18n';
import { createPublicApi } from './public-api';
import { createPrivateApi } from './private-api';
import * as membersLogic from './membersLogic';
import {
  maybeFixBrokenMenuItems,
  verifyMyAccountPage,
  verifyNoMissingLoginInADI,
  maybeSetManagingAppDefIdForMAPages,
  maybeRemoveEmptyInstallation,
  removeBrokenInstallation,
  maybeRemoveLeftoversFromUnsuccessfulInstallation,
  maybeFixRouterConfigurations,
  maybeFixLoginBarResponsiveLayout,
} from './data-fixers';
import { MembersAreaOnEventFn } from '../types/EditorAppModule';
import { AppEditorApi } from '@wix/platform-editor-sdk/js/definitions/appEditorApi';

const { APP_TOKEN } = constants;

async function onManagePages({ eventPayload, editorSDK }: { eventPayload?: any; editorSDK: EditorSDK }) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({ origin: 'editor' });
  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/managePages.html',
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

const onEvent: MembersAreaOnEventFn = async ({ eventType, eventPayload }, editorSDK) => {
  enforceSequentiality('onEventHandler', async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      return;
    }
    try {
      const _routers = await editorSDK.routers.getAll(APP_TOKEN);
      switch (eventType) {
        case 'createBlankPage': {
          onManagePages({ eventPayload, editorSDK });
          break;
        }
        case 'managePages': {
          onManagePages({ eventPayload: undefined, editorSDK });
          break;
        }
        case 'pageDeleted': {
          // Handling when a verticals TPA section is being deleted - removes menu items and router patterns, the page is removed by platform
          // Separate applications deletion (added with "addApplication") is handled by handleVerticalDeletion
          // To do: ask Editor Platform to do this on their side when deleting
          // Seems like we can't return the promise because it then does not execute on e.g. Bookings deletion..
          enforceSequentiality('handleVerticalSectionDeletion', () =>
            toMonitored('handleVerticalSectionDeletion', () =>
              membersIntegrationApi.handleVerticalSectionDeletion(editorSDK, eventPayload.pageRole),
            ),
          );
          break;
        }
        case 'uninstall': {
          openUninstallPanel(editorSDK);
          break;
        }
        case 'removePage': {
          openRemovePagePanel(editorSDK, eventPayload.pageRef);
          break;
        }
        case 'renameRouter':
          hasSocialPages(editorSDK).then((containsSocialPages) => {
            const height = containsSocialPages
              ? constants.RENAME_ROUTER_PANEL_HEIGHT + 150
              : constants.RENAME_ROUTER_PANEL_HEIGHT;
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/renameRouter.html',
              width: 744,
              height,
              shouldHideHeader: true,
              initialData: { routers: _routers, ...eventPayload },
            });
          });
          break;
        case 'componentAddedToStage':
          await componentsWrapper.handleCompAddedToStage(editorSDK, eventPayload.compRef);
          break;
        case 'siteWasPublished':
          publishSettingsForMembersAreaApps(editorSDK);
          break;

        // Apps manager
        case 'appActionClicked':
          switch (eventPayload && eventPayload.actionId) {
            case 'openMembersAreaPagesPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'pagesPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'addMemberPage': {
              onManagePages({ eventPayload, editorSDK });
              break;
            }
            case 'openMembersAddPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'addPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'openBadgesPage':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
              break;
            case 'openSiteMembersDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
              break;
            default:
              break;
          }
          break;

        /* end of possibly unused events */
        default:
          console.log(eventType, eventPayload);
      }
    } catch (e) {
      throw e;
    }
  });
};

async function maybeInstallMembersArea(editorSDK: EditorSDK, options: EditorReadyOptions) {
  if (!(await membersLogic.shouldInstall(editorSDK, options.firstInstall))) {
    return;
  }

  try {
    await toMonitored('install', () => membersLogic.install(editorSDK, options));
  } catch (e) {
    if (!(await shouldRevertOnInstallationErrors())) {
      log('Removing initial installation as it failed', { extra: { error: e.toString() } });
      await removeBrokenInstallation(editorSDK, true, true);
    }
    throw e;
  }
}

const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  maybeConductExperiments();

  appState.setEditorSDK(editorSDK);
  appState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  // @ts-ignore - blogWriterProfilesOnly does not exist on Origin.info type in Editor platform sdk types
  appState.setIsBlogWriterProfilesOnly(!!options.origin.info?.blogWriterProfilesOnly);
  appState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  appState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  try {
    await initializeMonitoring(editorSDK, options);
  } catch (e) {}

  const biService = await createBIService({ editorSDK });

  try {
    interactionStarted('editorReady');

    await runAndWaitForApproval(editorSDK, async () => {
      await maybeSetManagingAppDefIdForMAPages({ editorSDK, options });

      await pagesService.setStateForPages(editorSDK);

      // We see occasions of not entirely deleted MAs without the app actually being installed.
      // This should clean it up and allow a proper installation
      // This shouldn't be happening so need to investigate why this is happening
      await maybeRemoveLeftoversFromUnsuccessfulInstallation(editorSDK, options);

      // Delete empty MAs which won't install anyway to not cause further errors
      // Also don't expose public API in such case as it will not perform properly without MA components
      const { shouldContinueInitialization } = await maybeRemoveEmptyInstallation(editorSDK, options);
      if (!shouldContinueInitialization) {
        stopSequentialPromises();
        return;
      }

      // MA-84 investigation, making sure My Account page is always there as it has to be
      await verifyMyAccountPage(options, editorSDK);

      // Install MA and delete it if anything goes wrong
      await maybeInstallMembersArea(editorSDK, options);

      // EDIX-7884 - Routers somehow have items without actual pages, delete such configs in such case
      await maybeFixRouterConfigurations(editorSDK);

      // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
      await maybeFixBrokenMenuItems(editorSDK);

      // Try to fix login bar responsive layout on editorX
      await maybeFixLoginBarResponsiveLayout(editorSDK);

      // Remove MA if it is still unsuccessfully installed
      const successfullyInstalled = await applicationState.isApplicationReady(editorSDK, { shouldLog: true });
      if (successfullyInstalled) {
        // OB-19052 fixer - adds login bar when missing in ADI
        await verifyNoMissingLoginInADI(options.firstInstall, editorSDK);
      } else {
        if (options.firstInstall && (await shouldRevertOnInstallationErrors())) {
          log('isApplicationReady returned false after first install');
          throw new Error('MA not installed successfully');
        }
        await toMonitored('editorReady.removeComponentsForBrokenInstallation', () =>
          removeBrokenInstallation(editorSDK),
        );
        stopSequentialPromises();
      }

      await registerAlwaysAvailableApps(editorSDK);

      if (await shouldListenToComponentAddedToStageEvent()) {
        await componentsWrapper.registerToComponentAddedToStageEvent(editorSDK);
      }

      startSequentialPromises();
    });
    interactionEnded('editorReady');
  } catch (e) {
    biService.logInstallationFailure(e.toString());
    interactionFailed('editorReady', e);
    console.error('membersApplication installation failed.', e);
    console.error('things will certainly not work properly from here on');
    stopSequentialPromises();
    throw new Error('Members Area installation failed: ' + e);
  }
};

const getAppManifest: GetAppManifest = async (options, editorSDK) => {
  const t = await getTranslationFunction(editorSDK, true);
  return createAppManifest(editorSDK, t);
};

async function getControllerPresets() {
  return Promise.resolve([]);
}

export const exports_: ExportsFn = (editorSDK) => {
  appState.setEditorSDK(editorSDK);

  return {
    public: createPublicApi(editorSDK),
    private: createPrivateApi(editorSDK),
    editor: {
      beforeComponentRemoved: async (data) => {
        const shouldOpenConfirmationPanel = await shouldOpenConfirmationPanelOnLoginBarRemove();
        const isLoginBarBeingRemoved =
          data.componentDefinition.componentType === 'wysiwyg.viewer.components.LoginSocialBar';

        if (isLoginBarBeingRemoved && shouldOpenConfirmationPanel) {
          await openRemoveLoginBarConfirmationPanel(editorSDK);
        }
      },
    } as unknown as Partial<AppEditorApi>, //  updating @wix/platform-editor-sdk to the latest causes a lot of tests to fail,
  };
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exports_ as exports };
